import { useEffect, useState } from 'react'
import { Observable } from 'rxjs'

export function useObservable<T>(observable: Observable<T>): T | null {
  const [state, setState] = useState<T | null>(null)

  useEffect(() => {
    const sub = observable.subscribe((val: T) => {
      setState(val)
    })
    return (): void => sub.unsubscribe()
  }, [observable])

  return state
}
