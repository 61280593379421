import { loadState, saveState } from 'common/helpers'
import { produce } from 'immer'
import { BehaviorSubject } from 'rxjs'
import { logger } from 'common/logger'

export abstract class DataManager<T> {
  constructor(
    protected subject: BehaviorSubject<T>,
    protected key: string,
    isStale: boolean = false
  ) {
    if (!isStale && process.env.REACT_APP_USE_LOCAL === 'false') {
      const data = this.load()
      if (data) {
        this.subject.next(data)
      }
    }

    this.subject.subscribe(this.save.bind(this))
  }

  protected save(data: T): void {
    saveState(this.key, data)
  }

  protected load(): T | undefined {
    const data = loadState(this.key)
    if (data) {
      return data as T
    }
  }

  protected produce(modifier: (state: T) => void): void {
    const currentState = this.subject.getValue()
    const newState = produce(currentState, modifier)
    logger.info('New state for ' + this.key, newState)
    this.subject.next(newState)
  }

  get current(): T | null {
    return this.subject.getValue()
  }
}
