import { Subject } from 'rxjs'

export interface LinkShipperEvent {
  name: 'LinkShipper'
  shipperId: number
  shipperName: string
}

export interface UnlinkShipperEvent {
  name: 'UnlinkShipper'
}

export interface DisconnectedEvent {
  name: 'Disconnected'
}

export interface ShopifyLoadedEvent {
  name: 'ShopifyLoadedEvent'
  accessToken: string
}

export interface ShopifyInvalidToken {
  name: 'ShopifyInvalidToken'
}

export interface ShopifyRefreshTokenExpired {
  name: 'ShopifyRefreshTokenExpired'
}

type MEvent =
  | LinkShipperEvent
  | UnlinkShipperEvent
  | DisconnectedEvent
  | ShopifyLoadedEvent
  | ShopifyInvalidToken
  | ShopifyRefreshTokenExpired

export const globalBus = new Subject<MEvent>()
