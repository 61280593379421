import React from 'react'
import { Button, Card, Layout, Stack } from '@shopify/polaris'
import { ExternalMinor } from '@shopify/polaris-icons'

export const SectionShipWithUs: React.FunctionComponent = () => {
  return (
    <Layout.AnnotatedSection
      title='Ship with us'
      description='Contact us now to create an account and get access to Ninja Van’s shipping services!'
    >
      <Card sectioned>
        <Stack alignment='center'>
          <Stack.Item fill>Don’t have a Ninja Van account?</Stack.Item>
          <Stack.Item>
            <Button
              url={process.env.REACT_APP_CONTACT_US_LINK}
              external
              icon={ExternalMinor}
            >
              Contact us now
            </Button>
          </Stack.Item>
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  )
}
