// @flow
import React from 'react'
import { Banner, Layout } from '@shopify/polaris'

export const WarningUnsupportedCountry = () => {
  return (
    <Layout.Section>
      <Banner
        title='Looks like your Shopify store is not in a supported country for Ninja Dashboard integration.'
        status='info'
      >
        <p>
          We’re currently only supporting Shopify integration with Ninja
          Dashboard in the following countries: Singapore, Malaysia,
          Philippines. If applicable, you can switch to a different Shopify
          store in the relevant countries to use our integration services. Do
          note that we also only support domestic deliveries for this
          integration.
        </p>
      </Banner>
    </Layout.Section>
  )
}
