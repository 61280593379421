import React from 'react'
import './index.css'

export const Paragraph = (props: {
  gap?: string
  children: React.ReactNode
  flex?: boolean
}) => {
  let classNames = 'paragraph'
  if (props.gap === 'small') {
    classNames += ' paragraph-small'
  }
  if (props.flex) {
    classNames += ' flex'
  }
  return <p className={classNames}>{props.children}</p>
}
