import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import shopifyData from 'fixtures/shopifyData.json'
import { mapIsCountrySupported, filterValidShopOrigin } from './transformers'
import { logger } from 'common/logger'

export interface Linked {
  shopifyName: string
  nvName: string
}

export type Shop = {
  id: number
  name: string
  email: string
  domain: string
  primaryLocale: string
  countryCode: string
  countryName: string
  currency: string
  timezone: string
  shopOwner: string
}

export interface ShopifyData {
  shop?: Shop
  shopOrigin?: string
  accessToken?: string
  nvEmail?: string
  isCountrySupported: boolean
  isConnected?: boolean
}

const shopifyDataInitialValue =
  process.env.REACT_APP_USE_LOCAL === 'true'
    ? (shopifyData as ShopifyData)
    : {
        isCountrySupported: false
      }

logger.info('shopifyDataInitialValue ', shopifyDataInitialValue)

export const rawShopifyDataSubject = new BehaviorSubject<ShopifyData>(
  shopifyDataInitialValue
)
export const shopifyDataObservable: Observable<
  ShopifyData
> = rawShopifyDataSubject
  .pipe(mapIsCountrySupported)
  .pipe(filterValidShopOrigin)
  .pipe(distinctUntilChanged())
