import _ from 'lodash'
import { NVData } from 'types'

export function getAdminUrl(shopOrigin: string): string {
  return `https://${shopOrigin}/admin`
}

export function getAdminUrlForApp(shopOrigin: string): string {
  return `https://${shopOrigin}/admin/apps/${
    process.env.NODE_ENV === 'test'
      ? process.env.VITE_API_KEY
      : process.env.REACT_APP_API_KEY
  }`
}

export function verifyHmac(hmac: string | null, query: string): boolean {
  // TODO
  // Implement HMAC verification
  return true
}

export function loadState<T>(key: string): T | null {
  const raw: string | null = localStorage.getItem(key)

  if (!raw) {
    return null
  }

  let json
  try {
    json = JSON.parse(raw)
  } catch (e) {
    json = null
  }
  return json
}

export function saveState<T>(key: string, ret: T): void {
  localStorage.setItem(key, JSON.stringify(ret))
}

export const checkIsConnected = (nvData: NVData | null): boolean => {
  return !!_.get(nvData, 'accountInfo')
}
