import _ from 'lodash'
import { toCamelCase, toSnakeCase } from 'common/utils/data'
import { toastObservable, globalBus, ShopifyLoadedEvent } from 'observables'
import { filter } from 'rxjs/operators'
import { Shop } from 'types'
import { logger } from 'common/logger'
import { ShopifyResponse, UserInfo } from 'types'
import { create } from 'apisauce'

declare global {
  interface Window {
    countryCode?: string
  }
}

export const epiClient = create({
  baseURL: process.env.REACT_APP_BACKEND
    ? process.env.REACT_APP_BACKEND.replace(
        '@country@',
        window.countryCode || 'global'
      ) + '/shopify'
    : '',
  headers: {
    'content-type': 'application/JSON'
  },
  timeout: 10000
})
epiClient.addRequestTransform(request => {
  request.data = toSnakeCase(request.data)
})
epiClient.addResponseTransform(response => {
  response.data = toCamelCase(response.data)
  if (!response.status || response.status >= 400 || response.status < 200) {
    if (response.status === 401) {
      toastObservable.next({
        content: _.get(
          response.data,
          'error.message',
          _.get(response, 'data.nvErrorCode') === 'INVALID_ACCESS_TOKEN'
            ? 'Session expired, please login again.'
            : 'Please login again.'
        ),
        error: true
      })

      if (_.get(response, 'data.nvErrorCode') === 'INVALID_ACCESS_TOKEN') {
        globalBus.next({ name: 'ShopifyInvalidToken' })
      }
    } else if (response.status === 403) {
      // nv refresh token expired
      toastObservable.next({
        content: _.get(
          response.data,
          'error.message',
          _.get(response, 'data.nvErrorCode') === 'INVALID_ACCESS_TOKEN'
            ? 'Session expired, please login again.'
            : 'Please connect again.'
        ),
        error: true
      })
      globalBus.next({ name: 'ShopifyRefreshTokenExpired' })
    } else {
      toastObservable.next({
        content: _.get(
          response.data,
          'error.message',
          'Something is wrong, please try again later.'
        ),
        error: true
      })
    }
  }
  if (!response.ok) {
    logger.error(`Request failed with ${response.status}:`, response.data)
  }
})

export const updateCountry = function(countryCode: string): void {
  const base = process.env.REACT_APP_BACKEND
    ? process.env.REACT_APP_BACKEND.replace(
        '@country@',
        countryCode || 'global'
      ) + '/shopify'
    : ''
  epiClient.setBaseURL(base)
  if (process.env.REACT_APP_USE_LOCAL === 'true') {
    epiClient.setHeader('x-nv-system-id', countryCode)
  }
}

export async function verifyShopifyHmac(
  hmac: string,
  shop: string,
  timestamp: number
): Promise<boolean> {
  return new Promise(function(resolve) {
    // TODO: Call EPI endpoint for verifying hmac
    setTimeout(function() {
      if (hmac === shop && timestamp === 0) {
        resolve(true)
      } else {
        resolve(false)
      }
    }, 100)
  })
}

export async function getShopifyAccessToken(
  code: string,
  hmac: string,
  shop: string,
  host: string,
  timestamp: number
): Promise<{ accessToken?: string; shop?: Shop; isConnected?: boolean }> {
  const response = await epiClient.post<ShopifyResponse>(
    `accounts?code=${code}&hmac=${hmac}&host=${host}&shop=${shop}&timestamp=${timestamp}`,
    {}
  )
  if (response.ok && response.data) {
    const { accessToken } = response.data
    epiClient.setHeader('X-Shopify-Access-Token', accessToken)
    return response.data
  }

  return {}
}

export async function getNinjaVanAccessToken(
  shop: string,
  code: string,
  accessToken: string // callback page doesn't get event to set access token, so we need to pass in
): Promise<UserInfo | undefined> {
  const response = await epiClient.post<UserInfo>(
    `accounts/${shop}/connect`,
    {
      code
      // redirect_uri: process.env.REACT_APP_REDIRECT_URI
    },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  )
  if (response.ok) {
    return response.data
  } else {
    throw new Error(
      response.status + ' ' + _.get(response.data, 'error.message', '')
    )
  }
}

export async function linkShipper(
  shop: string,
  shipperId: number
): Promise<boolean> {
  const resp = await epiClient.post<void>(
    `accounts/${shop}/link?shipperId=${shipperId}`,
    {}
  )
  return resp.ok
}

export async function unlinkShipper(
  shop: string,
  shipperId: number
): Promise<boolean> {
  const resp = await epiClient.delete<void>(
    `accounts/${shop}/link?shipperId=${shipperId}`
  )
  return resp.ok
}

export async function disconnect(shop: string): Promise<boolean> {
  const resp = await epiClient.delete<void>(`accounts/${shop}/connect`)
  return resp.ok
}

export async function getUserInfo(shop: string): Promise<UserInfo | undefined> {
  const response = await epiClient.get<UserInfo>(`accounts/${shop}/connect`)
  if (response.ok) {
    return response.data
  } else {
    throw new Error(
      response.status + ' ' + _.get(response.data, 'error.message', '')
    )
  }
}

globalBus
  .pipe(filter(e => !!e && e.name === 'ShopifyLoadedEvent'))
  .subscribe(ev => {
    epiClient.setHeader(
      'Authorization',
      `Bearer ${(ev as ShopifyLoadedEvent).accessToken}`
    )
  })
