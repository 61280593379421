import _ from 'lodash'
import React, { ReactNode } from 'react'
import { Card, Layout, Spinner, TextStyle } from '@shopify/polaris'
import { Paragraph } from '../Paragraph'
import { Shipper } from 'types'
import { useObservable } from 'hooks/useObservable'
import {
  LoadingShippers,
  LoadingLinkingShipper,
  LoadingUnLinkingShipper
} from 'common/constants'
import {
  loading,
  LoadingPayload,
  nvDataObservable,
  shopifyDataObservable
} from 'observables'
import { filter } from 'rxjs/operators'
import Logo from 'assets/nv_logo.svg'
import ShopifyLogo from 'assets/shopify_logo.svg'
import './index.css'

const Description: React.FunctionComponent = () => (
  <>
    <Paragraph>
      To sync Shopify orders, link only one of your Ninja Dashboard shipper
      account. If you wish to switch shipper, select the linked shipper to
      unlink.
    </Paragraph>
    <Paragraph>
      *We only support domestic deliveries currently. Switch to a different
      Shopify store to see your shipper accounts in other countries.
    </Paragraph>
  </>
)

const linkingObservable = loading.pipe<LoadingPayload>(
  filter<LoadingPayload>(
    o =>
      o &&
      [LoadingLinkingShipper, LoadingUnLinkingShipper, LoadingShippers].indexOf(
        o.key
      ) >= 0
  )
)

interface LinkedAccount {
  shopifyName: string
  nvName: string
}

interface Props {
  title?: string
  children: ReactNode
}

export const ShipperLinking: React.FunctionComponent<Props> = (
  props: Props
) => {
  const loadingState = useObservable(linkingObservable)
  const shopifyData = useObservable(shopifyDataObservable)
  const nvData = useObservable(nvDataObservable)

  if (!shopifyData || !nvData) {
    return null
  }

  let linkedAccounts: LinkedAccount[] = _.get(
    nvData,
    'accountInfo.shippers',
    []
  )
    .filter((info: Shipper) => !!info.linkedTo)
    .map((info: Shipper) => {
      return { shopifyName: info.linkedTo, nvName: info.name }
    })
  linkedAccounts = _.sortBy(linkedAccounts, 'nvName')

  return (
    <Layout.AnnotatedSection
      title='Ninja Dashboard Shipper Account'
      description={<Description />}
    >
      <Card title={props.title || ''}>
        <Card.Section>
          {loadingState && loadingState.isLoading ? (
            <Spinner color='teal' />
          ) : (
            props.children
          )}
        </Card.Section>

        {/*Linked Shipper accounts*/}
        {linkedAccounts.length > 0 && (
          <Card.Section>
            <Paragraph>LINKED SHIPPER ACCOUNTS</Paragraph>
            <ul className='link-infos'>
              {linkedAccounts.map(({ shopifyName, nvName }) => {
                return (
                  <li key={shopifyName + '-' + nvName}>
                    <div className='link-info'>
                      <span>{nvName}</span>
                      <img src={Logo} alt='' />
                      <span className='shopify-name'>
                        <TextStyle variation='subdued'>
                          {' '}
                          Linked to {shopifyName.replace('.myshopify.com', '')}
                        </TextStyle>
                      </span>
                      <img src={ShopifyLogo} alt='' />
                    </div>
                  </li>
                )
              })}
            </ul>
          </Card.Section>
        )}
      </Card>
    </Layout.AnnotatedSection>
  )
}
