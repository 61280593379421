import { Authenticated } from 'guards/Authenticated'
import { NinjaVanCallback } from 'oauth/NinjaVanCallback'
import { ShopifyCallback } from 'oauth/ShopifyCallback'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import { Main } from './pages/Main'
import { logger } from 'common/logger'

if (process.env.NODE_ENV === 'development') {
  logger.info('-----------------------------')
  logger.info('|    DEVELOPMENT MODE       |')
  logger.info('-----------------------------')
}

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact={true} path='/shopify/auth/callback'>
          <ShopifyCallback />
        </Route>
        <Route exact={true} path='/ninjavan/auth/callback'>
          <NinjaVanCallback />
        </Route>
        <Authenticated>
          <Route path='/'>
            <Main />
          </Route>
        </Authenticated>
      </Switch>
    </Router>
  )
}

export default App
