import createApp from '@shopify/app-bridge'
import { Toast } from '@shopify/app-bridge-react'
import { shopifyDataObservable, toastObservable, globalBus } from 'observables'
import { Provider } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { AppProvider } from '@shopify/polaris'
import enTranslations from '@shopify/polaris/locales/en.json'
import { isInFrame } from 'common/constants'
import { getAdminUrl, getAdminUrlForApp } from 'common/helpers'
import invariant from 'invariant'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import * as types from 'types'
import { useObservable } from '../hooks/useObservable'
import { logger } from '../common/logger'
import { nvDataManager } from '../observables/managers'

export const Authenticated: React.FunctionComponent<{
  children: React.ReactNode
}> = props => {
  const toastMessage = useObservable(toastObservable)
  const shopifyData = useObservable(shopifyDataObservable)
  const shopOrigin = _.get(shopifyData, 'shopOrigin', '')
  const authenticated = !!_.get(shopifyData, 'accessToken')

  const [msgQ, setMsgQ] = useState<types.Toast[]>([])
  const [toastVisible, setToastVisible] = useState(false)
  const [ok, setOk] = useState(false)
  const shopifyConfig: types.ShopifyConfig = {
    apiKey: process.env.REACT_APP_API_KEY || '',
    shopOrigin
  }

  const toastTimeup = () => {
    if (msgQ.length) {
      setToastVisible(false)
      setMsgQ(q => {
        q.shift()
        return [...q]
      })
    }
  }

  useEffect(() => {
    if (toastMessage) {
      setMsgQ(q => {
        q.push(toastMessage)
        return [...q]
      })
    }
  }, [toastMessage])

  useEffect(() => {
    if (msgQ.length > 0) {
      setToastVisible(true)
    }
  }, [msgQ])

  useEffect(() => {
    if (shopifyData == null) {
      logger.warn('shopifyData is not available')
      return
    }
    invariant(!!shopOrigin, 'Shop origin must be valid string')
    logger.info('Shop origin %s', shopOrigin)
    if (isInFrame) {
      logger.info('Running in iframe')
      const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY || '',
        shopOrigin
      })
      if (!authenticated) {
        Redirect.create(app).dispatch(
          Redirect.Action.ADMIN_PATH,
          process.env.REACT_APP_PERMISSION_URL || ''
        )
      } else {
        setOk(true)
      }
    } else {
      logger.info('Running outside iframe')
      if (!authenticated) {
        logger.info('Not authenticated, redirecting...')
        window.location.assign(
          getAdminUrl(shopOrigin) +
            process.env.REACT_APP_PERMISSION_URL +
            `&shop=${shopOrigin}`
        )
      } else {
        if (process.env.REACT_APP_USE_LOCAL === 'true') {
          setOk(true)
        } else {
          window.location.assign(getAdminUrlForApp(shopOrigin))
        }
      }
    }
  }, [authenticated, shopOrigin, shopifyData])

  useEffect(() => {
    if (ok) {
      globalBus.next({
        name: 'ShopifyLoadedEvent',
        accessToken: _.get(shopifyData, 'accessToken', '')
      })
      nvDataManager.refreshData()
    }
  }, [ok, shopifyData])

  return ok ? (
    <AppProvider i18n={enTranslations}>
      <Provider config={shopifyConfig}>
        {props.children}
        {toastVisible && msgQ.length > 0 ? (
          <Toast onDismiss={toastTimeup} {...msgQ[0]} />
        ) : null}
      </Provider>
    </AppProvider>
  ) : null
}
