import React from 'react'
import enTranslations from '@shopify/polaris/locales/en.json'
import { AppProvider, Stack } from '@shopify/polaris'
import { Paragraph } from 'components/elements/Paragraph'
import { shopifyDataManager } from 'observables/managers'
import { useEffect } from 'react'
import { getAdminUrlForApp } from '../common/helpers'
import { Spinner } from '@shopify/polaris'

async function getAccessTokenAndRedirect(): Promise<void> {
  const rawQuery = window.location.search
  const query = new URLSearchParams(rawQuery)
  const code = query.get('code') || ''
  const hmac = query.get('hmac') || ''
  const shop = query.get('shop') || ''
  const host = query.get('host') || ''
  const timestamp = parseInt(query.get('timestamp') || '0', 10)

  if (shop) {
    const accessToken = await shopifyDataManager.obtainAccessToken(
      code,
      hmac,
      shop,
      host,
      timestamp
    )
    if (accessToken) {
      window.location.assign(getAdminUrlForApp(shop))
    }
  }
}

export const ShopifyCallback: React.FunctionComponent = () => {
  useEffect(() => {
    getAccessTokenAndRedirect()
  }, [])
  return (
    <AppProvider i18n={enTranslations}>
      <div style={{ marginTop: 200 }}>
        <Stack distribution='center'>
          <Spinner color='teal' />
          <Paragraph>Waiting to be redirected ...</Paragraph>
        </Stack>
      </div>
    </AppProvider>
  )
}
