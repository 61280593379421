import React from 'react'
import { Card, Layout, Link } from '@shopify/polaris'

export const Privacy = () => {
  return (
    <Layout.AnnotatedSection
      title='Privacy Policy'
      description={'You can view the Privacy Policy here at anytime.'}
    >
      <Card sectioned>
        <Link external url={process.env.REACT_APP_PRIVACY_LINK}>
          Ninja Van Shipping and Delivery Privacy Policy
        </Link>
      </Card>
    </Layout.AnnotatedSection>
  )
}
