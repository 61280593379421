import { createLogger, format, transports } from 'winston'

export const logger = createLogger({
  level:
    process.env.NODE_ENV === 'test'
      ? 'emerg'
      : process.env.NODE_ENV === 'development'
      ? 'info'
      : 'error',
  format: format.combine(format.splat(), format.colorize()),
  transports: [new transports.Console({ format: format.simple() })]
})
