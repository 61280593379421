import React, { useEffect, useMemo, useState, useContext } from 'react'
import { Redirect } from '@shopify/app-bridge/actions'
import { Button, Card, Layout, Stack } from '@shopify/polaris'
import { MainContext } from 'pages/Main'
import { useObservable } from 'hooks/useObservable'
import { shopifyDataObservable, globalBus, nvDataObservable } from 'observables'

export const SectionConnectAccount: React.FunctionComponent = () => {
  const [refreshTokenExpired, setRefreshTokenExpired] = useState(false)
  const { redirect } = useContext(MainContext)
  const data = useObservable(shopifyDataObservable)
  const nvData = useObservable(nvDataObservable)
  const event = useObservable(globalBus)

  useEffect(() => {
    if (event && event.name === 'ShopifyRefreshTokenExpired') {
      setRefreshTokenExpired(true)
    }
  }, [event])

  const isCountrySupported = data ? data.isCountrySupported : false

  const connectText = useMemo(() => {
    if (refreshTokenExpired && nvData && nvData.lastEmail) {
      return (
        <div>
          Session expired for <u>{nvData.lastEmail}</u>
        </div>
      )
    } else {
      return 'No account connected'
    }
  }, [nvData, refreshTokenExpired])

  return (
    <Layout.AnnotatedSection
      title='Ninja Van account'
      description='You need to create or connect a Ninja Van account to use Ninja Van Shipping'
    >
      <Card sectioned>
        <Stack alignment='center'>
          <Stack.Item fill>{connectText}</Stack.Item>
          <Stack.Item>
            <Button
              disabled={!isCountrySupported}
              primary
              onClick={(): void => {
                redirect &&
                  redirect.dispatch(
                    Redirect.Action.REMOTE,
                    process.env.NODE_ENV === 'test'
                      ? `${
                          process.env.VITE_AUTH_HOST
                        }/oauth2/index.html?client_id=${
                          process.env.VITE_AUTH_CLIENT_ID
                        }&state=${data && data.shopOrigin}`
                      : `${
                          process.env.REACT_APP_AUTH_HOST
                        }/oauth2/index.html?client_id=${
                          process.env.REACT_APP_AUTH_CLIENT_ID
                        }&state=${data && data.shopOrigin}`
                  )
              }}
            >
              {refreshTokenExpired ? 'Connect' : 'Connect Ninja Van account'}
            </Button>
          </Stack.Item>
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  )
}
