import React, { useState } from 'react'
import _ from 'lodash'
import enTranslations from '@shopify/polaris/locales/en.json'
import { AppProvider, Frame, Loading } from '@shopify/polaris'
import { useEffect } from 'react'
import { shopifyDataObservable } from 'observables'
import { nvDataManager, shopifyDataManager } from 'observables/managers'
import { getAdminUrlForApp } from 'common/helpers'
import { useObservable } from 'hooks/useObservable'

async function getInternalAccessTokenAndRedirect(
  token: string
): Promise<string | undefined> {
  const rawQuery = window.location.search
  const query = new URLSearchParams(rawQuery)
  const code = query.get('code') || ''
  const state = query.get('state') || ''

  // a trick to pass shop as state as the assumption is every redirect to
  // the app needs to indicate shop name in the url query param
  try {
    const userInfo = await nvDataManager.obtainAccessToken(state, code, token)
    if (userInfo) {
      window.location.assign(
        getAdminUrlForApp(_.get(shopifyDataManager, 'current.shopOrigin'))
      )
    }
  } catch (error) {
    if (error == null) return 'Error in obtaining the access token of the user.'
    if (error instanceof Error) {
      return error.message
    }
  }
}

export const NinjaVanCallback: React.FunctionComponent = () => {
  const shopifyData = useObservable(shopifyDataObservable)
  const [error, setError] = useState<string | undefined>('')

  useEffect(() => {
    async function exchange() {
      if (_.get(shopifyData, 'accessToken')) {
        const e = await getInternalAccessTokenAndRedirect(
          _.get(shopifyData, 'accessToken', '')
        )
        setError(e)
      }
    }
    exchange()
  }, [shopifyData])

  return (
    <AppProvider i18n={enTranslations}>
      {error ? (
        <div style={{ padding: '20px' }}>Error: {error}</div>
      ) : (
        <div style={{ height: '100px' }}>
          <Frame>
            <Loading />
          </Frame>
        </div>
      )}
    </AppProvider>
  )
}
