// Adopted from @nv/react-commons/src/Utils/DataUtils.js

import {
  isArray,
  isObject,
  camelCase,
  mapKeys,
  mapValues,
  snakeCase
} from 'lodash'

type TransformFn = (string?: undefined | string) => string

function toAnyCase(
  data: object,
  transformCase: TransformFn
): string[] | object {
  if (isArray(data)) {
    return data.map(d => toAnyCase(d, transformCase))
  } else if (!isObject(data)) {
    return data
  } else {
    return mapKeys(
      mapValues(data, d => toAnyCase(d, transformCase)),
      (value, key) => transformCase(key)
    )
  }
}

export function toSnakeCase(data: object) {
  return toAnyCase(data, snakeCase)
}

export function toCamelCase(data: object) {
  return toAnyCase(data, camelCase)
}
