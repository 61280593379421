import _ from 'lodash'
import {
  LoadingDisconnect,
  LoadingLinkingShipper,
  LoadingUnLinkingShipper
} from 'common/constants'
import {
  disconnect,
  getShopifyAccessToken,
  linkShipper,
  unlinkShipper,
  updateCountry
} from 'services/epi'
import { DataManager } from './DataManager'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { globalBus, loading } from 'observables'
import { Shop } from 'types'
import { ShopifyData } from 'types'
import { nvDataManager } from './index'

export class ShopifyDataManager extends DataManager<ShopifyData> {
  constructor(
    subject: BehaviorSubject<ShopifyData>,
    key: string,
    isStale: boolean
  ) {
    super(subject, key, isStale)
    const countryCode = _.get(
      this.current,
      'shop.countryCode',
      ''
    ).toLowerCase()
    window.countryCode = countryCode
    updateCountry(countryCode)
  }

  public setAccessToken(token: string): void {
    this.produce(s => {
      s.accessToken = token
    })
  }

  public setShopOrigin(shopOrigin: string): void {
    this.produce(s => {
      s.shopOrigin = shopOrigin
    })
  }

  public setShop(shop: Shop): void {
    this.produce(s => {
      s.shop = shop
    })
  }

  public deleteAccessToken(): void {
    this.produce(s => {
      delete s.accessToken
    })
  }

  public setNvEmail(email: string): void {
    this.produce(s => {
      s.nvEmail = email
    })
  }

  public async obtainAccessToken(
    code: string,
    hmac: string,
    shop: string,
    host: string,
    timestamp: number
  ): Promise<string | undefined> {
    this.clear()
    const response = await getShopifyAccessToken(code, hmac, shop, host, timestamp)
    if (response.accessToken) {
      this.setAccessToken(response.accessToken)
    } else {
      this.deleteAccessToken()
    }
    if (response.shop) {
      this.setShop(response.shop)
    }
    this.setConnected(!!response.isConnected)
    return response.accessToken
  }

  public async unlinkShipper(shipperId: number): Promise<boolean> {
    if (
      this.current === null ||
      !this.current.accessToken ||
      !this.current.shopOrigin
    ) {
      return false
    }
    loading.next({ key: LoadingUnLinkingShipper, isLoading: true })
    const success = await unlinkShipper(this.current.shopOrigin, shipperId)
    if (success) {
      await nvDataManager.refreshData()
      globalBus.next({ name: 'UnlinkShipper' })
    }
    loading.next({ key: LoadingUnLinkingShipper, isLoading: false })
    return success
  }

  public async linkShipper(
    _shipperId: string,
    shipperName: string
  ): Promise<void> {
    const shipperId = parseInt(_shipperId, 10)
    if (isNaN(shipperId)) {
      return
    }
    if (
      this.current === null ||
      !this.current.accessToken ||
      !this.current.shopOrigin
    ) {
      return
    }
    loading.next({ key: LoadingLinkingShipper, isLoading: true })
    const success = await linkShipper(this.current.shopOrigin, shipperId)
    if (success) {
      await nvDataManager.refreshData()
      globalBus.next({ name: 'LinkShipper', shipperId, shipperName })
    }
    loading.next({ key: LoadingLinkingShipper, isLoading: false })
  }

  public async disconnect(): Promise<boolean> {
    if (
      this.current === null ||
      !this.current.accessToken ||
      !this.current.shopOrigin
    ) {
      return false
    }
    loading.next({ key: LoadingDisconnect, isLoading: true })
    const success = await disconnect(this.current.shopOrigin)
    if (success) {
      this.setConnected(false)
      globalBus.next({ name: 'Disconnected' })
    }
    loading.next({ key: LoadingDisconnect, isLoading: false })
    return success
  }

  public setConnected(connected: boolean): void {
    this.produce(data => {
      data.isConnected = connected
    })
  }
  public clear(): void {
    this.produce(data => {
      return {}
    })
  }
}
