import React, { useState } from 'react'
import _ from 'lodash'
import { ShipperLinking } from 'components/elements/ShipperLinking'
import { Modal } from '@shopify/app-bridge-react'
import { Paragraph } from 'components/elements/Paragraph'
import { toastObservable } from 'observables'
import { nvDataObservable, shopifyDataObservable } from 'observables'
import { shopifyDataManager } from 'observables/managers'
import { useObservable } from '../../hooks/useObservable'
import { Button, Spinner } from '@shopify/polaris'
import { ExternalMinor } from '@shopify/polaris-icons'

export const SectionLinked: React.FunctionComponent = () => {
  const shopifyData = useObservable(shopifyDataObservable)
  const nvData = useObservable(nvDataObservable)

  const [active, setActive] = useState(false)
  if (!nvData) {
    return (
      <ShipperLinking>
        <Spinner color='teal' />
      </ShipperLinking>
    )
  }

  const shipper = _.find(_.get(nvData, 'accountInfo.shippers', []), {
    linkedTo: shopifyData && shopifyData.shopOrigin
  })
  const linkedShipperName = _.get(shipper, 'name')

  const dashLink = process.env.REACT_APP_DASHBOARD_HOST
  const unlink = async () => {
    setActive(false)
    if (shipper && shipper.id) {
      const success = await shopifyDataManager.unlinkShipper(shipper.id)
      if (success) {
        toastObservable.next({ content: 'Shipper is unlinked!' })
      }
    }
  }

  return (
    <>
      <ShipperLinking
        title={'Link a shipper account to ' + _.get(shopifyData, 'shop.name')}
      >
        <Paragraph gap='small'>
          You're currently linked to Shipper{' '}
          <span style={{ textDecoration: 'underline' }}>
            {linkedShipperName}
          </span>
        </Paragraph>
        <Paragraph flex>
          <Button onClick={() => setActive(true)}>Unlink current store</Button>{' '}
          <span style={{ margin: 'auto 10px' }}>or</span>{' '}
          <Button external icon={ExternalMinor} primary url={dashLink}>
            Go to Ninja Dashboard
          </Button>
        </Paragraph>
      </ShipperLinking>
      <Modal
        open={active}
        onClose={() => setActive(false)}
        title='Unlink current store?'
        primaryAction={{
          content: 'Unlink current store',
          onAction: unlink,
          destructive: true
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setActive(false)
          }
        ]}
        message='You won’t be able to sync your orders to Ninja Dashboard through Shopify. Don’t worry, any orders created in the Ninja Dashboard will not be lost.'
      />
    </>
  )
}
