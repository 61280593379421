import _ from 'lodash'
import { toastObservable } from 'observables'
import React, { useState } from 'react'
import { Modal } from '@shopify/app-bridge-react'
import { Button, Card, Layout, Spinner, Stack } from '@shopify/polaris'
import { useObservable } from 'hooks/useObservable'
import { loading, LoadingPayload, nvDataObservable } from 'observables'
import { shopifyDataManager } from 'observables/managers'
import Logo from 'assets/nv_logo.svg'
import { Paragraph } from 'components/elements/Paragraph'
import { filter } from 'rxjs/operators'

const DisconnectLoadingObservable = loading.pipe<LoadingPayload>(
  filter<LoadingPayload>(o => o.key === 'Disconnected')
)

export const SectionConnectedInfo: React.FunctionComponent = () => {
  const nvData = useObservable(nvDataObservable)
  const loading = useObservable(DisconnectLoadingObservable)
  const [active, setActive] = useState(false)

  const disconnect = async (): Promise<void> => {
    setActive(false)
    const success = await shopifyDataManager.disconnect()
    if (success) {
      toastObservable.next({ content: 'Account is disconnected!' })
    }
  }

  return (
    <>
      <Layout.AnnotatedSection
        title='Ninja Van account'
        description='You need to create or connect a Ninja Van account to use Ninja Van Shipping'
      >
        <Card sectioned>
          <Stack alignment='center'>
            <Stack.Item>
              <img src={Logo} alt='' />
            </Stack.Item>
            <Stack.Item fill>
              <Paragraph gap='small'>Ninja Dashboard</Paragraph>
              <Paragraph gap='small'>
                {_.get(nvData, 'accountInfo.email')}
              </Paragraph>
            </Stack.Item>
            <Stack.Item>
              {loading ? (
                <Spinner color='teal' />
              ) : (
                <Button onClick={(): void => setActive(true)}>
                  Disconnect
                </Button>
              )}
            </Stack.Item>
          </Stack>
        </Card>
      </Layout.AnnotatedSection>
      <Modal
        open={active}
        onClose={(): void => setActive(false)}
        title='Disconnect your Ninja Van account?'
        primaryAction={{
          content: 'Disconnect',
          onAction: disconnect,
          destructive: true
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: (): void => setActive(false)
          }
        ]}
        message="You won't be able to sync your orders to Ninja Dashboard through Shopify. Don’t worry, any orders created in the Ninja Dashboard will not be lost."
      />
    </>
  )
}
