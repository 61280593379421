import { Shipper, NVData, ShopifyData } from 'types'
import { filter, map } from 'rxjs/operators'
import _ from 'lodash'

export const mapIsCountrySupported = map(
  (shopifyData: ShopifyData): ShopifyData => {
    const countryCode = _.get(shopifyData, 'shop.countryCode')
    const isCountrySupported = _.includes(
      process.env.NODE_ENV === 'test'
        ? process.env.VITE_SUPPORTED_COUNTRIES
        : process.env.REACT_APP_SUPPORTED_COUNTRIES,
      countryCode
    )
    return Object.assign({}, shopifyData, { isCountrySupported })
  }
)

export const mapLinkingStep = map(
  ([nvData, shopifyData]: [NVData, ShopifyData]): string => {
    const isLinked = _.get(nvData, 'accountInfo.shippers', []).some(
      (s: Shipper) => s.linkedTo && shopifyData.shopOrigin === s.linkedTo
    )
    if (isLinked) {
      return '/step-3'
    }
    if (nvData.accountInfo) {
      return '/step-2'
    }
    return '/step-1'
  }
)

export const filterValidShopOrigin = filter<ShopifyData>(x => !!x.shopOrigin)
