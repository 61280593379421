import _ from 'lodash'
import { Shipper } from 'types'
import React, { useEffect, useState } from 'react'
import { Banner, Button, Select, SelectOption } from '@shopify/polaris'
import { useObservable } from 'hooks/useObservable'
import { nvDataObservable, shopifyDataObservable } from 'observables'
import { Paragraph } from '../elements/Paragraph'
import { logger } from 'common/logger'
import { shopifyDataManager } from 'observables/managers'
import { ShipperLinking } from '../elements/ShipperLinking'
import { ExternalMinor } from '@shopify/polaris-icons'

const NoShipperView: React.FunctionComponent = () => {
  return (
    <>
      <Banner status='warning'>
        Looks like you don't have available shippers for linking. You can either
        unlink existing stores, or contact Ninja Van Sales team to create a new
        shipper for your Ninja Van account.
      </Banner>
      <Paragraph>
        <Button
          external
          icon={ExternalMinor}
          primary
          url={process.env.REACT_APP_CONTACT_US_LINK}
        >
          Contact us
        </Button>
      </Paragraph>
    </>
  )
}

export const SectionLinkShipper: React.FunctionComponent = () => {
  const shopifyData = useObservable(shopifyDataObservable)
  const nvData = useObservable(nvDataObservable)
  const [selected, setSelected] = useState('')
  const [options, setOptions] = useState<SelectOption[]>([])

  function handleSelectChange(val: string): void {
    setSelected(val)
  }

  function linkShipper(): void {
    if (!selected) {
      return
    }
    logger.info('Selected shipper: ' + selected)
    const ops = options as { value: string; label: string }[]
    const found = ops.find(o => o.value === selected)
    if (found) {
      shopifyDataManager.linkShipper(selected, found.label)
    }
  }

  useEffect(() => {
    if (_.get(nvData, 'accountInfo.shippers.length', 0)) {
      setOptions(
        _.get(nvData, 'accountInfo.shippers', [])
          .filter((shipper: Shipper) => {
            return (
              shipper.country.toLocaleLowerCase() === window.countryCode &&
              shipper.linkedTo === undefined
            )
          })
          .map((shipper: Shipper) => {
            return { label: shipper.name, value: shipper.id + '' }
          })
      )
    }
  }, [nvData])

  return (
    <ShipperLinking
      title={'Link a shipper account to ' + _.get(shopifyData, 'shop.name', '')}
    >
      {options.length ? (
        <>
          <div style={{ width: 300 }}>
            <Select
              label='Select a shipper account to link'
              placeholder='Select...'
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />
          </div>
          <Paragraph>
            <Button primary onClick={linkShipper} disabled={!selected}>
              Link a shipper
            </Button>
          </Paragraph>
        </>
      ) : (
        <NoShipperView />
      )}
    </ShipperLinking>
  )
}
