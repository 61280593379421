import shopifyData from 'fixtures/shopifyData.json'
import { logger } from 'common/logger'
import { isInFrame } from 'common/constants'
import { verifyHmac } from 'common/helpers'

export const getShopOrigin = (rawQuery: string): [string, boolean] => {
  if (process.env.REACT_APP_USE_LOCAL === 'true') {
    return [shopifyData.shopOrigin, false]
  }
  const query = new URLSearchParams(rawQuery)

  logger.info(query)
  let previousDataIsStale = false

  // hacky way to use state to store shop
  // so we can support use of multiple stores at the same time
  if (query.has('hmac') && query.has('shop')) {
    if (verifyHmac(query.get('hmac'), rawQuery)) {
      if (!isInFrame) {
        previousDataIsStale = true
      }
      return [query.get('shop') || '', previousDataIsStale]
    }
  } else if (
    window.location.href.indexOf('ninjavan.co') >= 0 &&
    query.has('state')
  ) {
    return [query.get('state') || '', previousDataIsStale]
  }
  return ['', previousDataIsStale]
}
