import { BehaviorSubject } from 'rxjs'
import _ from 'lodash'
import { DataManager } from './DataManager'
import { getNinjaVanAccessToken, getUserInfo } from 'services/epi'
import { logger } from 'common/logger'
import { UserInfo, NVData, Shipper } from 'types'
import { loading } from 'observables'
import { LoadingConnectedInfo } from 'common/constants'

export class NVDataManager extends DataManager<NVData> {
  constructor(
    private shop: string,
    protected subject: BehaviorSubject<NVData>,
    protected key: string
  ) {
    super(subject, key)
  }

  async obtainAccessToken(
    shop: string,
    code: string,
    token: string
  ): Promise<UserInfo | undefined> {
    const userInfo = await getNinjaVanAccessToken(shop, code, token)
    if (userInfo) {
      this.setAccountInfo(userInfo)
    } else {
      this.setAccountInfo()
    }
    return userInfo
  }

  async refreshData(): Promise<void> {
    try {
      loading.next({ key: LoadingConnectedInfo, isLoading: true })
      const userInfo = await getUserInfo(this.shop)
      if (!userInfo) {
        this.clearAccountInfo()
        return
      }
      this.setAccountInfo(userInfo)
    } catch (e) {
      this.setAccountInfo()
      logger.error(e instanceof Error ? e : { message: 'Error' })
    } finally {
      loading.next({ key: LoadingConnectedInfo, isLoading: false })
    }
  }

  clearAccountInfo(): void {
    this.setAccountInfo()
  }

  setAccountInfo(userInfo: UserInfo | undefined = undefined): void {
    const shippers: Shipper[] = _.get(userInfo, 'shippers', [])
    const proShippers = shippers.filter(s => {
      return s.shipperType === 'Pro'
    })
    _.set(userInfo || {}, 'shippers', proShippers)
    this.produce(data => {
      data.accountInfo = userInfo
      if (_.get(userInfo, 'email')) {
        data.lastEmail = userInfo!.email
      }
    })
  }
}
