import { UserInfo } from 'types'
import { logger } from 'common/logger'
import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import nvData from 'fixtures/nvData.json'

export interface NVData {
  accountInfo?: UserInfo,
  lastEmail: string
}

const nvDataInitialValue =
  process.env.REACT_APP_USE_LOCAL === 'true' ? (nvData as NVData) : {lastEmail: ''}

logger.info('nvDataInitialValue ', nvDataInitialValue)

export const rawNVDataSubject = new BehaviorSubject<NVData>(nvDataInitialValue)
export const nvDataObservable: Observable<NVData> = rawNVDataSubject.pipe(
  distinctUntilChanged()
)
