import { Context, TitleBar } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { SectionShipWithUs } from '../components/step1/SectionShipWithUs'
import { logger } from 'common/logger'

import { Layout, Tabs } from '@shopify/polaris'
import React, { useContext, useEffect } from 'react'
import { routeObservable, shopifyDataObservable } from '../observables'
import { useObservable } from 'hooks/useObservable'
import { Route, Switch, useHistory } from 'react-router'
import { SectionConnectAccount } from '../components/step1/SectionConnectAccount'
import { WarningUnsupportedCountry } from '../components/step1/WarningUnsupportedCountry'
import { SectionLinkShipper } from '../components/step2/SectionLinkShipper'
import { SectionConnectedInfo } from '../components/step2/SectionConnectedInfo'
import { Privacy } from '../components/Privacy'
import { SectionLinked } from '../components/step3/SectionLinked'
import { MainContextType } from '../types'
import { ClientApplication } from '@shopify/app-bridge'

export const MainContext = React.createContext<MainContextType>({})
/**
 * 3 state before linking to a shipper successfully
 *
 * 1. not connected               route: /step-1
 * 2. connected, but not linked   route: /step-2
 * 3. connected and linked        route: /step-3
 */

export const Main: React.FC = () => {
  const app = useContext(Context)
  const data = useObservable(shopifyDataObservable)
  const isCountrySupported = data ? data.isCountrySupported : false
  const route = useObservable(routeObservable)
  const history = useHistory()

  logger.info('state', data)

  useEffect(() => {
    if (route) {
      history.replace(route)
    }
  }, [history, route])

  if (!app) {
    return null
  }

  const redirect = Redirect.create(app as ClientApplication<any>)
  app.subscribe(
    Redirect.ActionType.REMOTE,
    (payload: Redirect.RemotePayload) => {
      // Do something with the redirect
      logger.info(`Navigated to ${payload.url}`)
    }
  )

  return (
    <MainContext.Provider value={{ redirect }}>
      <Tabs
        tabs={[
          {
            id: 'nv-shipper-account',
            content: 'Ninja Van Shipper Account',
            accessibilityLabel: 'Ninja Van Shipper Account',
            panelID: 'nv-shipper-account'
          }
        ]}
        selected={0}
      >
        <TitleBar title='Ninja Van Shipper Account' />
        <main style={{ margin: '0 auto', maxWidth: '1000px', padding: 20 }}>
          <Layout>
            {!isCountrySupported && <WarningUnsupportedCountry />}

            {/*account connection status*/}
            <Switch>
              <Route exact path='/step-1'>
                <SectionConnectAccount />
              </Route>
              <Route exact path='/step-2'>
                <SectionConnectedInfo />
              </Route>
              <Route exact path='/step-3'>
                <SectionConnectedInfo />
              </Route>
            </Switch>

            {/*shipper linking status*/}
            <Switch>
              <Route exact path='/step-1'>
                <SectionShipWithUs />
              </Route>
              <Route exact path='/step-2'>
                <SectionLinkShipper />
              </Route>
              <Route exact path='/step-3'>
                <SectionLinked />
              </Route>
            </Switch>

            {/*privacy policy*/}
            <Privacy />
          </Layout>
        </main>
      </Tabs>
    </MainContext.Provider>
  )
}
