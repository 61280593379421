import { getShopOrigin } from 'common/utils/url'
import { ShopifyDataManager } from './ShopifyDataManager'
import { NVDataManager } from './NVDataManager'
import { rawShopifyDataSubject, globalBus, rawNVDataSubject } from 'observables'
import invariant from 'invariant'

const [shopOrigin, isStale] = getShopOrigin(window.location.search)
invariant(!!shopOrigin, 'Unable to get shop origin!')

// singleton
export const nvDataManager = new NVDataManager(
  shopOrigin,
  rawNVDataSubject,
  shopOrigin + '_nv_data'
)

// singleton
export const shopifyDataManager = new ShopifyDataManager(
  rawShopifyDataSubject,
  shopOrigin + '_shopify_data',
  isStale
)

shopifyDataManager.setShopOrigin(shopOrigin)

globalBus.subscribe(ev => {
  switch (ev.name) {
    case 'LinkShipper':
    case 'UnlinkShipper':
      break
    case 'Disconnected':
      nvDataManager.clearAccountInfo()
      break
    case 'ShopifyInvalidToken':
      shopifyDataManager.deleteAccessToken()
      break
  }
})
